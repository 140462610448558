<template>
  <div v-if="!isLoading">
    <ListCategoryDesktop
      :content="this.content"
      :category="this.carousel"
      v-if="this.carousel.content == 'categories'"
    />
    <ListCategory
      :content="this.content"
      :category="this.carousel"
      v-if="this.carousel.content == 'categories'"
    />
    <ListShops
      :content="this.content"
      :list="this.carousel"
      v-if="this.carousel.content == 'shops'"
    />
    <ListProducts
      :content="this.content"
      :list="this.carousel"
      v-if="this.carousel.content == 'products'"
    />
    <ImCarousel
      :content="this.content"
      :carousel="this.carousel"
      :autoplay="this.autoplay"
      :pagination="this.pagination"
      :desktopScreen="this.desktopScreen"
    />
  </div>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import Loader from './shared/Loader.vue'
import ListCategory from './ListCategory/ListCategory.vue'
import ListCategoryDesktop from './ListCategoryDesktop/ListCategoryDesktop.vue'
import ListShops from './ListTiendasOficiales/ListTiendasOficiales.vue'
import ListProducts from './ListProducts/ListProducts.vue'
import ImCarousel from './Carousel.vue'
import { http } from '@/services/http'

export default {
  name: 'MainCarousel',
  props: {
    src: { type: String, required: true, default: null },
    autoplay: { type: String, required: false, default: '0' },
    pagination: { type: String, required: false, default: '1' },
    desktopScreen: { type: String, required: false, default: 'full' }
  },
  components: {
    Loader,
    ListCategory,
    ListCategoryDesktop,
    ListShops,
    ListProducts,
    ImCarousel
  },
  data () {
    return {
      content: {},
      carousel: [],
      isLoading: true
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.isLoading = true
      http.get(this.src).then(res => {
        let data = res.data
        this.content = data.content
        this.carousel = data.carousel
        this.isLoading = false
      })
    }
  }
}
</script>
