<template lang="pug">
.Carousel(:class="carousel.content")
  .container-fluid
    .Carousel-clearfix.row(:class="{ 'Carousel--shops': isShops, 'Carousel--medium': isMedium }")
      h2.Carousel-title.col-xs-12.col-sm-12(v-if="carousel.name") {{ carousel.name }} &nbsp;
        a.Carousel-link(:href="carousel.link", :alt="carousel.name", v-if="carousel.link") Ver todas
      v-carousel.Carousel-container(
        :autoplay="isAutoPlay",
        :autoplay-hover-pause="true",
        :autoplay-timeout="3000",
        :easing="'ease-out'",
        :loop="true",
        :navigation-enabled="true",
        navigation-next-label=">",
        navigation-prev-label="<",
        :pagination-enabled="isPagination",
        pagination-active-color="#949494",
        pagination-color="#d8d8d8",
        :per-page="1",
        :per-page-custom="perPage",
        :scroll-per-page="false",
        :speed="1500",
        :min-swipe-distance="60")
        v-slide.Carousel-slide(v-if="carousel.content == 'categories'", v-for="category in content", :key="category.id")
          im-card-category.Carousel-item(:category="category")
        v-slide.Carousel-slide(v-if="carousel.content == 'products'", v-for="product in content", :key="product.id_product")
          im-card-product.Carousel-item(:product="product")
        v-slide.Carousel-slide(v-if="carousel.content == 'shops'", v-for="shop in content", :key="shop.id_official_store")
          im-card-shop.Carousel-item(:shop="shop")
</template>

<script>
import ImCardCategory from '@/components/CardCategory.vue'
import ImCardProduct from '@/components/CardProduct.vue'
import ImCardShop from '@/components/CardShop.vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'ImCarousel',
  props: {
    carousel: { type: Object, required: true, default: null },
    content: { type: Array, required: true, default: null },
    autoplay: { type: String, required: false, default: '0' },
    pagination: { type: String, required: false, default: '1' },
    desktopScreen: { type: String, required: false, default: 'full' }
  },
  components: {
    'v-carousel': Carousel,
    'v-slide': Slide,
    ImCardCategory,
    ImCardProduct,
    ImCardShop
  },
  computed: {
    isShops () {
      return this.carousel.content === 'shops'
    },
    isAutoPlay () {
      return parseInt(this.autoplay) === 1
    },
    isMedium () {
      return this.desktopScreen === 'medium'
    },
    isPagination () {
      return parseInt(this.pagination) === 1
    },
    perPage () {
      let perPage = []
      switch (this.desktopScreen) {
        case 'full-6':
          perPage.push([0, 2], [480, 3], [960, 4], [1152, 6])
          break
        case 'full':
          perPage.push([0, 1], [480, 2], [960, 3], [1152, 4])
          break
        case 'full-shops':
          perPage.push([0, 1], [480, 2], [500, 3], [1000, 4])
          break
        case 'medium':
          perPage.push([0, 1], [480, 2], [960, 1], [1152, 2])
          break
        case 'small':
          perPage.push([0, 1], [480, 2], [960, 1], [1152, 1])
          break
        default:
          perPage.push([0, 1], [480, 2], [960, 1], [1152, 1])
      }
      return perPage
    }
  }
}
</script>

<style lang="scss">
  .VueCarousel {
    &-pagination {
      display: none;
    }
    &-slide {
      flex-shrink: 1;
    }
    &-navigation {
        &-button {
          margin-top: 38px;
          height: 50px;
          width: 20px;
          line-height: 0;
          position: absolute;
          margin-top: -10px;
          font-size: 0;
          overflow: hidden;
          outline: none;
          text-decoration: none;
          background: #c0c0c0 !important;
          padding: 13px 5px;
        }

        &-prev {
          left: 4px !important;
        }

        &-next {
          right: 5px !important;
        }

        &-prev, &-next {
          padding: 0 !important;
          color: #fff !important;
          border-radius: 3px;

          &:before {
            font-family: "FontAwesome";
            font-size: 20px;
            line-height: 22px;
            background: #c0c0c0;
          }

          &:hover {
            color: black !important;
          }
        }

        &-next {
          &:before {
            content: "\f105";
            padding-left: 3px;
          }
        }

        &-prev {
          &:before {
            content: "\f104";
          }
        }
      }
  }

  /* @media all and (min-width: 480px) {

  } */
  /* @media all and (min-width: 768px) {

  } */

  @media all and (min-width: 991px) {
    .VueCarousel {
      &-pagination {
        display: block;
      }
    }

    .Carousel--medium {
      // margin: 1em 1em 1em auto !important;
      width: 105.2%;
    }
  }
</style>

<style lang="scss" scoped>
  .Carousel {
    font-family: Gustan,sans-serif;
    margin: 1em;

    &-title {
      font-size: 1.5rem;
      margin: 0;
      z-index:1
    }

    &-link{
      font-size: 1rem;
      color: #333;
      color: #8e8e8e;

      &:hover {
        text-decoration: underline;
      }

      i {
        display: none;
        font-family: "FontAwesome";
        font-style: normal;
        padding-left: .3rem;

        &:before {
          font-size: .8em;
          padding-top: .5em;
        }
      }
    }
  }

  .carousel--medium {
    padding-top: 17px;
    padding-bottom:4px;
  }

  .Carousel--medium .Carousel-title {
    display: none;
  }

  @media all and (min-width: 360px) {
    .Carousel {

      &-title {
        font-size: 25px;
        font-weight: 400;
        padding-top: .7em;
      }

      &-link{
        font-size: 1.5rem;
      }
    }
  }

  /* @media all and (min-width: 410px) {

  } */

  /* @media all and (min-width: 768px) {

  } */

  @media all and (min-width: 991px) {
    .Carousel {

      &-title {
        i {
          display: inline-block;
        }
      }
    }
    .carousel--medium{
      padding-top: 21px;
      padding-bottom:4px;
  }
  }

  @media (max-width: 480px){
    .Carousel {
        display: none;
    }
  }

  @media (min-width: 481px){
    .Carousel {
        display: block;
    }
  }

  .Carousel.categories {
      display: none;
  }
</style>
