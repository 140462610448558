<template lang="pug">
  .Card(aria-hidden='false')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      .Card-image
        figure
          img.replace-2x.img-responsive(loading="lazy" :src='category.image', :alt='category.name', :title='category.name', itemprop='image')

      h2.Card-title(itemprop='name') {{ category.name }}

      a.Card-link(:href='category.link', :title='category.name', itemprop='url')
</template>

<script>
export default {
  name: 'CardCategory',
  props: {
    category: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
  .Card {
    font-family: Gustan,sans-serif;
    // padding: 0 .7em;
    padding: .5em 1rem;
    // width: auto;
    width: 98%;
    height: auto;
    // height: 100%;

    &-container {
      // background: white;
      position: relative;
      // border: 1px solid #d6d4d4;
      border: 1px solid transparent;
      // box-shadow: 1px 1px 2px #dddddd;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, .08);
      // height: 100%;
      max-width: 75vw;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      max-height: 192px;

      &:hover {
        outline: 1px solid #d1181e;
        cursor: pointer;
      }
    }

    &-image figure {
      min-height: 7.75em;

      img {
        // width: 50%;
        margin: auto;
      }
    }

    &-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }

    &-title {
      background: #d1181e;
      color:#fff;
      font-size: 1.2em;
      font-weight: 500;
      margin: 0;
      padding: .5em 1em;
      position: absolute;
      bottom: 10%;
      left: -3%;
      text-align: center;
      text-transform: uppercase;

      &::after {
        position: absolute;
        bottom: -20%;
        left: 3px;
        z-index: -1;
        content: '';
        transform: rotate(45deg);
        display: block;
        border: 6px solid #961015;
      }
    }

    @media all and (min-width: 360px) {
      .Card {
        &-image figure {
          min-height: 8.85em;
        }
      }
    }

    /* @media all and (min-width: 410px) {

    } */

    /* @media all and (min-width: 768px) {

    } */

    /* @media all and (min-width: 991px) {
      .Card {}
    } */
  }
</style>
