<template lang="pug">
  .Card(aria-hidden='false')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      .Card-image
        figure
          img(loading="lazy" :src='changeImageLink(shop.image)', :alt='shop.name', :title='shop.name', itemprop='image')
        h2.leyenda-shop {{shop.leyenda ? shop.leyenda.toUpperCase() : ''}}

      //- h5.Card-title(itemprop='name') {{ shop.name }}

      a.Card-link(:href='shop.link', :title='shop.name', itemprop='url')
</template>

<script>
export default {
  name: 'CardShop',
  props: {
    shop: { type: Object, required: true },
    changeImageLink: {type: Function, default(link) {
      let array_link_separado = link.split("/");
      let array_nombre_imagen = array_link_separado[array_link_separado.length - 1].split(".");

      let result           = '';
      let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for ( var i = 0; i < 6; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      array_nombre_imagen[0] += result;
      array_link_separado[array_link_separado.length - 1] = array_nombre_imagen.join(".");
      
      return array_link_separado.join("/");
    }}
  }
}
</script>

<style lang="scss" scoped>
  .Card {
    font-family: Gustan,sans-serif;
    padding: .5em;
    margin: 0px;
    width: 100%;
    height: auto;
    border-radius: 1em;

    &-container {
      background: white;
      padding: 0px;
      position: relative;
      max-width: 75vw;
      margin: 0px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: .4em;
    }

    &-image figure {
      img {
        width: 100%;
        height: 11em;
      }
    }

    &-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }

    &-title {
      font-size: 1.4em;
      font-weight: normal;
      margin: 0;
      text-align: center;
      text-transform: capitalize;
    }

    .leyenda-shop{
      background-color: #AF2127;
      color: #FFFFFF;
      font-weight: 400;
      font-size: .55em;
      padding: 1em 0.2em 1em 0.2em;
      margin: 0px !important;
      text-align: center;
      min-height: 3.1em;
      border-radius: 0px 0px 0.4em 0.4em;
    }

    @media all and (min-width: 360px) {
      .Card {
        &-image figure {
          // min-height: 8.85em;
          min-height: 7.75em;
        }
      }
    }

    /* @media all and (min-width: 410px) {

    } */

    /* @media all and (min-width: 768px) {

    } */

    @media all and (min-width: 991px) {
      .Card {
        &-image figure {
          min-height: 8.85em;
        }
      }
    }

    @media (min-width: 600px) {
      .Card{
        &-image figure {
          img {
            height: 16em;
          }
        }
      }
    }

    @media (min-width: 800px) {
      .leyenda-shop{
        font-size: .8em;
      }
    }

    @media (min-width: 950px) {
      .Card{
        &-image figure {
          img {
            height: 17em;
          }
        }
      }

      .Card-container{
        width: 15em;
      }
    }

    @media (min-width: 1025px){
      .Card{
        &-image figure {
          img {
            height: 20em;
          }
        }
      }
      
      .Card-container{
        width: 18.9em;
      }

      .leyenda-shop{
        font-size: 1em;
      }
    }
  }
</style>
