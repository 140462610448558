import { numberFormat, integers, decimals } from '@/filters/number-format'
import { http } from '@/services/http'
import BlockwishlistBtn from '@/components/shared/BlockwishlistBtn'

export default {
  name: 'CardFeatured',
  components: {
    BlockwishlistBtn
  },
  props: {
    src: { type: String, required: true, default: null }
  },
  data () {
    return {
      product: {},

      isLoading: true
    }
  },
  methods: {
    getData () {
      this.isLoading = true

      http.get(this.src).then(res => {
        let data = res.data

        this.product = data

        this.isLoading = false
      })
    }
  },
  computed: {
    costQuota () {
      let price = parseFloat(this.product.price.final)
      let quotas = parseInt(this.product.price.quota_without_interest)

      let result = price / quotas

      return result
    },
    discount () {
      let reduction = this.product.price.reduction
      let result = parseInt(Math.round(reduction * 100))
      return `${result}%`
    }
  },
  filters: {
    numberFormat,
    integers,
    decimals
  },
  mounted () {
    this.getData()
  }
}
