import CardCategoryList from '@/components/CardCategoryList/CardCategoryList.vue'

export default {
  name: 'ListCategoryDesktop',
  props: {
    category: { type: Object, required: true, default: null },
    content: { type: Array, required: true, default: null }
  },
  components: {
    CardCategoryList
  }
}
