import Vue from 'vue'
import App from '@/App.vue'
import ElectroRecharge from '@/components/ElectroRecharge.vue'
import CardProduct from '@/components/CardProduct.vue'
import CardFeatured from '@/components/CardFeatured'
import MainCarousel from '@/components/MainCarousel.vue';
// import Http from '@/services/http'
// import NumberFormat from '@/filters/number-format'

Vue.config.productionTip = false

// Vue.use(Http)
// Vue.use(NumberFormat)

Vue.component('im-electro-recharge', ElectroRecharge)
Vue.component('im-card-product', CardProduct)
Vue.component('im-card-featured', CardFeatured)
Vue.component('main-carousel', MainCarousel)

const config = {}

if (process.env.NODE_ENV !== 'production') {
  config
    .render = h => h(App)
}

document.addEventListener('DOMContentLoaded', function () {
  window.vm = new Vue(config).$mount('#app-view')
})

document.addEventListener('VueForceRender', function () {
  window.vm.$destroy()
  delete window.vm
  window.vm = new Vue(config).$mount('#app-view')
})
