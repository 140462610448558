/**
 * number formatting helper functions
 * can be used in vue filters
 */
import numeral from 'numeral'

/**
 * apply a numeral.js formatting string to an input value
 * @param {Number} val input value to be changed
 * @param {String} [format='0,0'] numeral.js format string
 * @return {String} formatted output string
 */

numeral.register('locale', 'ar', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: (number) => {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '$'
  }
})

const register = {}

const numberFormat = (val, format = '0,000.00') => {
  numeral.locale('ar')
  return numeral(val).format(format)
}

const integers = (val) => {
  return numberFormat(val).slice(0, -3)
}

const decimals = (val) => {
  return numberFormat(val).slice(-2)
}

register.install = function (Vue) {
  Vue.filter('numberFormat', numberFormat)
}

export default register

export { numberFormat, integers, decimals }
