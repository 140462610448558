export default {
    name: 'CardShopList',
    props: {
      shop: { type: Object, required: true },
      changeImageLink: {type: Function, default(link) {
        let array_link_separado = link.split("/");
        let array_nombre_imagen = array_link_separado[array_link_separado.length - 1].split(".");

        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < 6; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        array_nombre_imagen[0] += result;
        array_link_separado[array_link_separado.length - 1] = array_nombre_imagen.join(".");
        
        return array_link_separado.join("/");
      }}
    }
  }