import ImCardProduct from '@/components/CardProductList/CardProductList.vue'

export default {
  name: 'ListProducts',
  props: {
    list: { type: Object, required: true, default: null },
    content: { type: Array, required: true, default: null }
  },
  components: {
    ImCardProduct
  }
}
