<template lang="pug">
  .container
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/category/26") //- Destacados
    //- .row
      .col-md-5
        figure(style="padding: 45px 0 0 30px;")
          img(src="http://www.funnyandhappy.com/wp-content/uploads/2014/f/funny-and-entertaining-toasts.jpg", style="box-shadow: 0 0 10px 2px rgba(0,0,0,.08);")
      .col-md-7
        im-carousel(src="https://icbcclub-dev.aper.net/v1-api/carousel/category/1", :desktop-screen='"medium"') //- Destacados
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/categories/29")
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/category/21") //- Informática
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/category/8") // Fravega
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/category/22") //- Vinos
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/shops/3", :pagination="0", :desktop-screen='"full-6"') //- Tiendas Oficiales
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/category/6") //- Tecnología
    //- im-carousel(src="https://apistore-icbc-dev.aper.com/carousel/category/11") //- Deportes
    //- im-carousel(src="http://localhost:3333/carousel/products/11")
    //- im-electro-recharge(destiny="/")
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
  .container-fluid {
    background: white;
  }
</style>
