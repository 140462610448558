import CardShopList from '@/components/CardTiendasOficiales/CardTiendasOficiales.vue'

export default {
  name: 'ListShops',
  props: {
    list: { type: Object, required: true, default: null },
    content: { type: Array, required: true, default: null }
  },
  components: {
    CardShopList
  }
}
