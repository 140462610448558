<template lang="pug">
  .Card.ajax_block_product(aria-hidden='false', :data-id-product='product.id', :data-id-product-attribute='product.id_product_attribute')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      .Card-header(v-if="product.supplier && product.supplier !== undefined")
        .Card-header-logo_supplier
          figure.container-logo_supplier
            img.replace-2x.img-responsive(loading="lazy" :src='product.supplier.image', :title='product.supplier.name', :alt='product.supplier.name')

        .Card-header-delivery(v-if="product.marks", v-for="mark in product.marks", :key="mark.id")
          figure.container-logo_delivery
            img(loading="lazy" :src='mark.image', :title='mark.name', :alt='mark.name')

      .Card-body
        .Card-image
          figure
            img.replace-2x.img-responsive(loading="lazy" :src='product.cover', :alt='product.name', :title='product.name', itemprop='image')

        h2.Card-name(itemprop='name') {{ product.name }}

        .Card-payments
          .Card-payments-container
            .Card-reduction(v-if="product.price && product.price.reduction")
              span.Card-old-price.bold $ {{ product.price.list | integers }}
              small.bold {{ product.price.list | decimals }}
              .Card-percent(v-if="product.price.reduction")
                span.Card-percent-reduction -{{ discount }} OFF
            .Card-reduction(v-else)
            blockwishlist-btn(:id_product='product.id', :id_product_attribute='product.id_product_attribute')

          h3.Card-price.bold(v-if="product.price") $ {{ product.price.final | integers }}
            small.bold {{ product.price.final | decimals }}
          span.Card-points.bold(v-if="product.points && product.points.length > 0")
              |  ó {{ product.points[0].amount | numberFormat('0,000') }} puntos

          .Card-quota.bold(v-if="product.price && product.price.quota_without_interest")
            p {{ product.price.quota_without_interest }}
              | x $ {{ costQuota | integers }}
              small.quota-bold {{ costQuota | decimals }}
              |  sin interés
          .Card-quota(v-else)

      a.Card-link(:href='product.link', :title='product.name', itemprop='url')
</template>

<script>

import { numberFormat, integers, decimals } from '@/filters/number-format'
import BlockwishlistBtn from './shared/BlockwishlistBtn'

export default {
  name: 'CardProduct',
  components: {
    BlockwishlistBtn
  },
  props: {
    product: { type: Object, required: true }
  },
  methods: {
    parseProduct () {
      if (typeof this.product === 'string') {
        this.product = JSON.parse(this.product)
      }
    }
  },
  computed: {
    costQuota () {
      let price = parseFloat(this.product.price.final)
      let quotas = parseInt(this.product.price.quota_without_interest)

      let result = price / quotas

      return result
    },
    discount () {
      let reduction = this.product.price.reduction
      let result = parseInt(Math.round(reduction * 100))
      return `${result}%`
    }
  },
  filters: {
    numberFormat,
    integers,
    decimals
  },
  beforeMount () {
    this.parseProduct()
  },
  beforeUpdate () {
    this.parseProduct()
  }
}

</script>

<style lang="scss" scoped>
  $color-black: black;
  $color-red: #c4161c;
  $color-grey: #464646;
  $color-brown:#635843;
  $width-card: 75vw;
  $root-font-size: 14px;

  .Card {
    font-family: Gustan,sans-serif;
    font-size: $root-font-size;
    padding: .5em;
    width: 100%;
    height: auto;

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }

    &-best-price {

      &-amount {
        font-size: 20px;
        font-weight: 600;
        color: #635843;
        margin-bottom: 7px;

        #small-price{
          color: #635843;
        }
      }

      &-text {
        p {
          font-weight: 400;
          color: #635843;
          -ms-flex-item-align: left;
          align-self: left;
          font-size: 12px;
          line-height: 1.5em;
          margin: 0;
          text-align: left;
          margin-bottom: 10px;
        }
      }
    }

    &-container {
      background: white;
      padding: 10px;
      position: relative;
      min-height:  470px;
      min-width: 261px;
      max-width: $width-card;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 5px;

      &:hover {
        outline: 1px solid #d1181e;
        cursor: pointer;
      }

      @media (max-width: 767px) {
        min-width: 100%;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      &-logo_supplier {
        height: 50px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: start;

        img {
          max-width: 150px;
          max-height: 50px;
        }

      }

      &-delivery {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: end;
        align-items: center;
      }

      &-delivery figure {
        img {
          max-width: 50px;
          max-height: 50px;
        }
      }

    }

    &-body {
      width: 100%;
    }

    &-image {
      height: 230px;
      figure {
        width: 100%;
        height: 100%;
        img {
          max-height: 100%;
          margin: auto;
          position: relative;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
        }
      }
      @media (min-width: 1024px) {
        height: 240px;
      }
    }

    &-link {
      position: absolute;
      top: 0;
      top: 10%;
      right: 0;
      bottom: 0;
      left: 0;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }

    &-name {
      font-size: 16px;
      line-height: 1.1;
      margin-bottom: 8px;
      height: 2em;
      text-align: left;
      text-transform: capitalize;
      color: #333;
      font-weight: 600;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &-old-price {
      color: #333;
      text-decoration: line-through;
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-payments {
      &-container {
        display: flex;
        justify-content: space-between;
      }
    }

    &-percent {
      display: inline-block;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-percent-reduction {
      color: #af2127;
      font-size: 14px;
      margin-left: 0.45em;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-points {
      font-size: 12px;
      margin: 1.1em 0;
      min-height: 1.15em;
      color: #333;
      font-weight: 600;
      margin-left: 3px;

      small {
        font-size: 0.9em;
        color: black;
        font-weight: 600;
      }
    }

    &-price {
      font-size: 20px;
      margin: 0;
      font-weight: 600;
      color: #333;
      display: inline-block;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &-quota {
      font-weight: 400;
      font-size: 14px;
      min-height: 1.43em;
      margin-top: 0.2em;
      color: #00A650;

      .quota-bold {
        font-size: 10px;
        color: #00A650;
      }

      @media (max-width: 767px) {
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &-reduction {
      padding: 0;
      height: 1.2em;
      margin-bottom: 0.7em;
      font-weight: 100;
    }

    small {
      color: #333333;
      font-size: .65em;
      font-weight: inherit;
      position: relative;
      top: -0.4em;
    }

    hr {
      border-top: 1px solid #EBEBEB;
      margin: 0.875em 0 0.688em 0;
    }
  }

  @media all and (min-width: 360px) {
    $root-font-size: 16px;

    .Card {
      font-size: $root-font-size;

      &-best-price {
        margin-left: 4px;
      }
    }
  }

  @media (max-width: 480px) {
    .product_list .col-xs-6 .Card {

      &-container {
        padding-left: 5px;
        padding-right: 5px;
      }

      &-header {
        height: 40px;

        &-logo_supplier {
          width: 100px;
          height: 40px;

          img {
            max-width: 100px;
            max-height: 40px;
          }
        }

        &-delivery {
          height: 40px;
          width: 40px;
        }

        &-delivery figure img {
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
  }


</style>
