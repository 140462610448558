import { numberFormat, integers, decimals } from '@/filters/number-format'

export default {
  name: 'CardProductList',
  props: {
    product: { type: Object, required: true }
  },
  methods: {
    parseProduct () {
      if (typeof this.product === 'string') {
        this.product = JSON.parse(this.product)
      }
    }
  },
  computed: {
    costQuota () {
      let price = parseFloat(this.product.price.final)
      let quotas = parseInt(this.product.price.quota_without_interest)

      let result = price / quotas

      return result
    },
    discount () {
      let reduction = this.product.price.reduction
      let result = parseInt(Math.round(reduction * 100))
      return `${result}%`
    }
  },
  filters: {
    numberFormat,
    integers,
    decimals
  },
  beforeMount () {
    this.parseProduct()
  },
  beforeUpdate () {
    this.parseProduct()
  }
}
